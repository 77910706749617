var $ = jQuery.noConflict();

$(document).ready(function ($) {

    "use strict";

    var lang = $("meta[name=language]").attr('content');

    var places;
    places = new Bloodhound({
        datumTokenizer: function (places) {
            return Bloodhound.tokenizers.obj.whitespace(places);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            url: '/places?q=%QUERY&lang=' + lang,
        }
    });
    places.initialize();
    $('.form-control.typeahead-places').typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        }, {
            name: 'places',
            displayKey: function (places) {
                return places.name + (places.province_code ? ' (' + places.province_code + ')' : '');
            },
            source: places.ttAdapter()
        }
    ).bind('typeahead:select', function(ev, suggestion) {
        $('input[name=' + $(this).attr('ref') + ']').val(suggestion.police_code);
    }).blur(function () {
        if ($('input[name=' + $(this).attr('ref') + ']').val()=='') {
            $(this).val(''), $(this).addClass('alert-border');
        } else {
            $(this).removeClass('alert-border');
        }
    });

    var citizenship;
    citizenship = new Bloodhound({
        datumTokenizer: function (citizenship) {
            return Bloodhound.tokenizers.obj.whitespace(citizenship);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            url: '/countries?q=%QUERY&lang=' + lang,
        }
    });
    citizenship.initialize();
    $('.form-control.typeahead-citizenship').typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        }, {
            name: 'citizenship',
            displayKey: function (citizenship) {
                return citizenship.name;
            },
            source: citizenship.ttAdapter()
        }
    ).bind('typeahead:select', function(ev, suggestion) {
        $('input[name=' + $(this).attr('ref') + ']').val(suggestion.police_code);
    }).blur(function () {
        if ($('input[name=' + $(this).attr('ref') + ']').val() == '') {
            $(this).val(''), $(this).addClass('alert-border');
        } else {
            $(this).removeClass('alert-border');
        }
    });

    var country;
    country = new Bloodhound({
        datumTokenizer: function (country) {
            return Bloodhound.tokenizers.obj.whitespace(country);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            wildcard: '%QUERY',
            url: '/countries?q=%QUERY&lang=' + lang,
        }
    });
    country.initialize();
    $('.form-control.typeahead-country').typeahead({
            hint: true,
            highlight: true,
            minLength: 1
        }, {
            name: 'country',
            displayKey: function (country) {
                return country.name;
            },
            source: country.ttAdapter()
        }
    ).bind('typeahead:select', function (ev, suggestion) {
        $('input[name=' + $(this).attr('ref') + ']').val(suggestion.iso_code);
        _limitProvinceToCountryCode();
    }).blur(function () {
        if ($('input[name=' + $(this).attr('ref') + ']').val() == '') {
            $(this).val(''), $(this).addClass('alert-border');
        } else {
            $(this).removeClass('alert-border');
        }
    });

    //provincia is max 2 char for italy. Unbound for other countries
    function _limitProvinceToCountryCode() {
        var country = $('input[name=country_code]').val()
        if (country === 'IT') {
            var prov = $('input[name=province]').val()
            $('input[name=province]').attr('maxlength', 2);
            $('input[name=province]').val(prov && prov.length > 2 ? prov.substr(0, 2) : prov);
        } else {
            $('input[name=province]').attr('maxlength', 100)
        }
    }

    $('input[name=country_code]').change(function (e) {
        _limitProvinceToCountryCode();
    });

    $('a[data-toggle=tab]').on('click', function(e) {
        $('input[name=type]').val($(this).data('value'));
    });

    _limitProvinceToCountryCode();

});
